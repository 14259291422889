
import getJSONLD from '~/utils/structured-data';
import getUrl from '~/utils/get-url';
import { commonProps } from '../../utils/prismic';
import { mapState } from 'vuex';

export default {
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    ...commonProps,
  },
  head() {
    const { baseUrl } = getUrl(this.$nuxt.context.req);
    return {
      script: [
        getJSONLD('breadcrumb', { links: this.layout.breadcrumbs, baseUrl }),
      ],
    };
  },
  computed: mapState(['layout']),
};
